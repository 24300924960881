import $ from 'jquery';
import acquisitionsTracker from '../../../../../../app/static/app/js/packages/acquisitions_tracker/acquisitions_tracker';
import zxcvbn from '../vendor/zxcvbn/dist/zxcvbn';

import { page } from '../const';
import { conditionalRedirect } from '../utils';


var ghWindow,
    $forms = $('#home-main-signup-form, #home-alt-signup-form'),
    $form = $($forms.filter(':visible')[0]),
    $ghSignup = $('#github-signup-link'),
    passwordField = document.querySelector('#signpass'),
    usernameField = document.querySelector('#signname'),
    passStrength = document.querySelector('#sign-pass-strength'),
    passSuggestions = document.querySelector('#sign-pass-suggestions'),
    githubmail = localStorage.getItem('githubmail'),
    githubcode = localStorage.getItem('githubcode');

$forms.submit(function (e) {
    e.preventDefault();
    var data = {}, $inputs = $(this).find('input');
    data.username = document.getElementById('signname').value;
    data.password = document.getElementById('signpass').value;
    data.email = document.getElementById('signmail').value;
    data.csrfmiddlewaretoken = $('form input[name="csrfmiddlewaretoken"]').val();
    data.utm_source = $('form input[name="utm_source"]').val();
    data.utm_medium = $('form input[name="utm_medium"]').val();
    data.utm_campaign = $('form input[name="utm_campaign"]').val();
    data.referrer = $('form input[name="referrer"]').val();
    data.signup_type = (typeof githubcode == 'string') ? 'oauth' : 'email';
    data.acquisition_path = JSON.stringify(acquisitionsTracker.getTrackingParameters());
    var $button = $form.find('button');
    $button.defaultText = $button.text();
    $button.text('Please wait').attr('disabled', 'disabled');
    $.post('/request/user/create', data, function () {
        acquisitionsTracker.clearTrackingParameters();
        ga('send', 'event', 'user', 'signup', 'from home');
        conditionalRedirect('/' + window.location.search);
    }).error(function (res) {
        var status_code = res.status;
        res = res.responseText;
        if (res){
            try{
                res = JSON.parse(res);
                var resText = '<ul>';
                for (var key in res) {
                    resText += '<li><span style=font-weight:500>';
                    resText += key.charAt(0).toUpperCase() + key.slice(1);
                    resText += '</span>: ' + res[key];
                    resText += '</li>';
                }
                resText += '</ul>';
                if (status_code == 409) {
                    resText += '<p>Did you want to <a href="/login">Log in</a>?</p>';
                } else {
                    console.log(status_code);
                }
                res = resText;
            }catch(e){
            }
        }
        $('#eventField').removeClass('info').addClass('error').addClass('active').html(res);
        $('#eventField2').removeClass('info').addClass('error').addClass('active').html(res);
    }).error(function () {
        $button.text($button.defaultText).removeAttr('disabled');
    });
});

$ghSignup.click(function (e) {
    ghWindow = window.open('/github_auth?redirect=1', '_blank');
});

function ghNoAccount(githubmail) {
    githubmail = githubmail || localStorage.getItem('githubmail');
    var text = '<span style="font-weight: 600;">Great!</span> Now sign up in SourceLair and we will automatically ';
    text += 'connect your new account with GitHub.<br />';
    $('#eventField').removeClass('error').addClass('info').html(text).addClass('active');
    $('.plans-container').addClass('centered');
    $('.github-related').hide();
    $('#signmail').val(githubmail);
    var $firstEmpty = $('#signname, #signpass').filter(function() { return this.value === ""; }).first();
    $firstEmpty.focus();
}

// HACK: We need this exposed to window, in order for the oauth_callback tab to access it.
window.ghNoAccount = ghNoAccount

/*
    *  If githubmail and github code exist, then trigger
    *  ghNoAccount
    */
if (githubmail && githubcode) {
    ghNoAccount(githubmail);
}

if (passwordField) {
    passwordField.addEventListener('input', function(e) {
        var analysisResults,
            hints;

        if (this.value !== '') {
            analysisResults = zxcvbn(this.value, [usernameField.value]);

            passStrength.setAttribute('data-strength', analysisResults.score);

            hints = '<ul>';
            analysisResults.feedback.suggestions.forEach(function(suggestion) {
                hints += '<li>' + suggestion + '</li>';
            });
            analysisResults.sequence.some(function(sequence) {
                if (sequence.dictionary_name && (sequence.dictionary_name === 'user_inputs')) {
                    hints += '<li>Do not include your username in your password</li>';
                    return true;
                }
            });
            hints += '</ul>';

            passSuggestions.innerHTML = hints;
            if (hints !== '') {
                passSuggestions.classList.add('has-content');
            } else {
                passSuggestions.classList.remove('has-content');
            }
        } else {
            passSuggestions.innerHTML = '';
            passSuggestions.classList.remove('has-content');
            passStrength.removeAttribute('data-strength');
        }

    });
}

/*
    * Make repeated mistakes more visible
    */
$forms.find('.buttons-div button').click(function() {
    $('[id^="eventField"]').removeClass('active');
    $('.uName-div label').removeClass('bubble--right').removeClass('bubble--error').removeClass('bubble--important');
    $('.uMail-div label').removeClass('bubble--right').removeClass('bubble--error').removeClass('bubble--important');
    $('.uPass-div label').removeClass('bubble--right').removeClass('bubble--error').removeClass('bubble--important');
});

if (page == 'home') {
    window.onscroll = function () {
        if (window.scrollY >= $('main section:nth-child(2)')[0].offsetTop) {
            $('#topLogin').addClass('scrolled');
        } else {
            $('#topLogin').removeClass('scrolled');
        }
    };
}
