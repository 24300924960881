import Raven from '../error_reporting/sentry';


export default {
    parse: function(text) {
        try {
            return JSON.parse(text);
        } catch(e) {
            Raven.captureException(e, {
                extra: {
                    text: text
                }
            });
            return undefined;
        }
    }
};
