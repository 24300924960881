import $ from 'jquery';
import { page } from '../const';
import { conditionalRedirect } from '../utils';


$(function () {
    var $form = $('#login-form');
    var $button = $form.find('button').first();
    var githubmail = localStorage.getItem('githubmail');
    var githubcode = localStorage.getItem('githubcode');


    $form.submit(function (e) {
        e.preventDefault();
        var data = {};
        data.password = $('#loginpass').val();
        var usernameOrEmail = $('#loginname').val();
        if (usernameOrEmail.indexOf('@') > -1)
            data.email = usernameOrEmail;
        else
            data.username = usernameOrEmail;
        data.remember = document.getElementById('rembox').checked;
        $button = $form.find('button').first();
        $button.defaultText = $button.text();
        $button.text('Please wait').attr('disabled', 'disabled');
        data.csrfmiddlewaretoken = $('form input[name="csrfmiddlewaretoken"]').val();
        $.post('/request/session/create', data, function () {
            conditionalRedirect($('#loginnext').val());
        }).error(function (res) {
            var status = res.status,
                responseText = res.responseText;
            if (status == 429) {
                responseText = 'Rate limit exceeded, please try again in 30 seconds.';
            } else if (responseText == 'No such user') {
                responseText = 'No user corresponds to the given username.';
            }
            if (status == 401) {
                $('#eventField').removeClass('info').addClass('error active').html(responseText);
            } else {
                $('#eventField').removeClass('info').addClass('error active').text(responseText);
            }
        }).error(function () {
            $button.text($button.defaultText).removeAttr('disabled');
        });
    });
    $form.find('input').focus(function () {
        $('#eventField.error').removeClass('error').removeClass('active').html('');
    });

    function setUpGitHubInfo () {
        var text = '<span style="font-weight: 600;" >Great!</span> One last step.<br />';
        text += 'Enter your password for this time only and you \'re done.';
        $('#eventField').removeClass('error').addClass('info').html(text).addClass('active');
        $('.github-specific').hide();
        $('#loginname').val(githubmail);
        $('#loginpass').focus();
    }

    // HACK: We need this exposed to window, in order for the oauth_callback tab to access it.
    window.setUpGitHubInfo = setUpGitHubInfo;

    var $ghButton = $('.githubLogin'), ghWindow;
    $ghButton.click(function (e) {
        e.preventDefault();
        ghWindow = window.open('/github_auth?redirect=1', '_blank');
    });

    /*
    *  If githubmail and github code exist, then trigger
    *  setUpGitHubInfo
    */
    if (githubmail && githubcode && page == 'login') {
        setUpGitHubInfo();
    }

    /*
        * Make repeated mistakes more visible
        */
    $('.loginForm .buttons-div button').click(function() {
        $('#eventField').removeClass('active');
    });
});
