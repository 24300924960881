

if (typeof Raven == 'undefined') {
    var stub = {};
    stub.captureException = function() {
        throw arguments[0];
    };
    stub.captureMessage = function() {};
    stub.captureBreadcrumb = function() {};
    stub.setUserContext = function() {};
    stub.lastEventId = function() {
        return 'sentry-id';
    };
    window.Raven = stub;
}

export default Raven;
