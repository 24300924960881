import $ from 'jquery';


var plansTable = $('.plans-table'),
    chosen = 'Chosen',
    exports = {};

function choosePlan (plan) {
    plansTable.find('tr.active').removeClass('active');

    var currentButton = plansTable.find('button.active');

    currentButton.removeClass('active');
    currentButton.text(currentButton.data('original-text'));

    var planClass = 'plan-' + plan,
        planSelector = '.' + planClass,
        nextButton = $(planSelector).find('button');

    $(planSelector).addClass('active');

    nextButton.data('original-text', nextButton.text());
    nextButton.text(chosen).addClass('active');
    plansTable.trigger('plan:choose');
}

plansTable.find('button').on('click', function () {
    choosePlan($(this).data('plan'));
});

exports.choosePlan = choosePlan;
exports.table = plansTable;

export default exports;
