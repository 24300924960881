import $ from 'jquery';
import plans from '../../../../../../billing/static/billing/js/components/plans';
import { page } from '../../const';


(function () {
    if (page != 'join/plan') {
        return;
    }

    var localStoragePlan = localStorage.getItem('plan'),
        initialPlan = (localStoragePlan) ? localStoragePlan : 'free',
        choosePlan = plans.choosePlan;

    localStorage.removeItem('plan');

    $('.plans-table button').on('click', function () {
        choosePlan($(this).data('plan'));
    });

    choosePlan(initialPlan);

    $('.complete-account button').on('click', function () {
        var search = window.location.search,
            plan = $('.plans-table button.active').data('plan'),
            queryNext = /next=([^&]+)/.exec(search),
            next = queryNext ? queryNext[1] : '/workspaces/welcome/';

        if (plan !== 'free') {
            search = '?plan=' + plan + '&next=' + next;
            next = '/account/billing/payment' + search;
        }

        window.location = next;
        $(this).attr('disabled', 'disabled').text('Please wait');
    });
})();
