import { page } from '../const';


(function () {
    if (page != 'tos') {
        return;
    }
    /*
     * Navbar position function
     */

    var navBarWrapper = document.getElementsByClassName('responsiveWrapper')[0];
    var navBar = document.getElementById('pageNavigation');
        navBar.computedHeight = $(navBar).height();
        navBar.computedWidth = $(navBarWrapper).width();
        navBar.locked = false;
    var headerHeight = document.getElementsByTagName('header')[0].offsetHeight;
    var startDistance = $(navBarWrapper).offset().top - headerHeight;
    var endDistance = $('footer').offset().top - headerHeight;
    var chokePoint = endDistance-(navBar.computedHeight*1.5);

    var setNavDimensions = function(watDo) {
        switch(watDo) {
            case 'recalc'	:
                navBar.computedHeight = $(navBar).height();
                navBar.computedWidth = $(navBarWrapper).width();
                navBar.style.width = navBar.computedWidth+'px';
                navBar.style.height = navBar.computedHeight+'px';
                break;
            case 'reset'	:
                navBar.style.top = '';
                navBar.style.position = '';
                navBar.style.width = '';
                navBar.style.height = '';
                break;
            default			:
                navBar.style.position = "fixed";
                navBar.style.top = headerHeight+'px';
                navBar.style.width = navBar.computedWidth+'px';
                navBar.style.height = navBar.computedHeight+'px';
        }
    };

    $(window).scroll(function() {
        if (window.scrollY > startDistance) {

            if ((chokePoint - window.scrollY) <= 0){
                //console.log('threshold passed');
                if(!navBar.locked) {
                    navBar.locked = true;
                    navBar.style.top = $(navBar).offset().top - navBar.computedHeight +'px';
                    navBar.style.position = "relative";
                }
            } else {
                if(navBar.locked) {
                    navBar.locked = false;
                }
                setNavDimensions();
            }
        } else {
            setNavDimensions('reset');
        }
    });

    window.onresize = function() {
        setNavDimensions('recalc');
        startDistance = $(navBarWrapper).offset().top - headerHeight;
        endDistance = $('footer').offset().top - headerHeight;
        chokePoint = endDistance-(navBar.computedHeight*1.5);
    };


    /*
     * Auxiliary functions and variable for section selection
     */
    var activeSection;

    var doSectionSwitch = function(target) {
        if(activeSection){
            activeSection.classList.remove('active');
        }
        target.classList.add('active');
        activeSection=target;
    };

    var setActiveSection = function(target) {
        //var target = targetSection;
        setTimeout(function() {
            doSectionSwitch(target);
        }, 1000);

    };

    /*
     * Anchor scripts
     */
    $(function() {
        /*
         * Set anchors to navbar
         */
        $('#pageNavigation div a').each(function() {
            var anchor = this.children[1].textContent.toLowerCase().split(/,\ |\ /).join('-');
            this.setAttribute('href', '#'+anchor);
            //console.log(anchor);
        });
        /*
         * Set ids to sections
         */
        $('#sectionsWrapper section').each(function() {
            if(!this.hasAttribute('id')) {
                var sectionID = this.children[0].textContent.toLowerCase().match(/\ \w.*$/).toString().substr(1).split(/,\ |\ /).join('-');
                //console.log(sectionID);
                this.setAttribute('id', sectionID);
                /*
                 * Permalink creation
                 */
                var permalink = document.createElement('a');
                permalink.setAttribute('href','#'+sectionID);
                permalink.setAttribute('class','permalinks');
                permalink.innerHTML = "Direct link";
                this.appendChild(permalink);
            }
        });
        /*
         * Smooth scroll function
         */
        $('#pageNavigation a[href^=#]:not([href=#])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top //- $('header').height()
                    }, 1000);
                    setActiveSection(target[0]);
                    return false;
                }
            }
        });
    });
})();
