import $ from 'jquery';


function submitToken() {
    var button = $form.find('button');

    button.attr('disabled', 'disabled');

    $.post('/request/user/verify_email/update', {
        token: $form.find('input')[0].value,
        csrfmiddlewaretoken: $('form input[name="csrfmiddlewaretoken"]').val()
    }, function(res) {
        window.location.pathname = '/';
    }).error(function () {
        $form.parent().parent().find('#eventField').removeClass('info').addClass('error').addClass('active').text('Token is invalid');
    }).always(function () {
        button.removeAttr('disabled');
    });
}


$(function () {
    var $form = $('#verify-email-form');

    $form.submit(function(e) {
        e.preventDefault();
        submitToken();
    });
});
