var $form = $('#forgot-email-form');

$form.submit(function (e) {
    e.preventDefault();
    $form.find('button').attr('disabled', 'disabled');
    $form.parent().parent().find('#eventField').removeClass('active').text('');

    $.post('/request/user/passwordtoken/create', {
        email: $form.find('input').val(),
        csrfmiddlewaretoken: $('form input[name="csrfmiddlewaretoken"]').val()
    }, function () {
        $('.prompt, .success').toggle();
    }).error(function (res) {
        res = JSON.parse(res.responseText);
        var txt = '';
        for (var i in res) {
            txt = txt + res[i][0] + ' ';
        }
        $form.parent().parent().find('#eventField').removeClass('info').addClass('error').addClass('active').text(txt);
    }).always(function () {
        $form.find('button').removeAttr('disabled');
    });
});

$form.find('input').focus(function () {
    $form.parent().parent().find('#eventField.error').html('').removeClass('error').removeClass('active');
});
