import { page } from '../const';


(function () {
    if (page != 'resetpassword') {
        return;
    }

    if (window.location.pathname == '/home/resetpassword') {
        $('#eventField').addClass('active');
    } else {
        $('.main-content').show().find('input').focus();
        var token = window.location.pathname.split('/');
        token = token[token.length - 1];
        var $form = $('form');
        $form.submit(function (e) {
            var originalText = $form.find('button').text();
            e.preventDefault();
            $form.find('button').text('Please wait').attr('disabled', 'disabled');
            $.post( "/request/user/password/update" , {
                token: token ,
                password: $form.find('input').val(),
                csrfmiddlewaretoken: $('form input[name="csrfmiddlewaretoken"]').val()
            } , function ( res ) {
                $.post('/request/session/create', {
                    username: res.username,
                    password: $form.find('input').val(),
                    csrfmiddlewaretoken: $('form input[name="csrfmiddlewaretoken"]').val()
                }, function () {
                    window.location.pathname = '/';
                }).error(function (e) {
                    res = res.responseText;
                    $form.parent().parent().find('#eventField').removeClass('info').addClass('error').addClass('active').text(res);
                });
                $form.text('Password got reset successfully. Please wait while we log you in');
            }).error(function (res) {
                res = res.responseText;
                if (res){
                    try{
                        res = JSON.parse(res);
                        var resText = '<ul>';
                        for (var key in res) {
                            resText += '<li><span style=font-weight:500>';
                            resText += key.charAt(0).toUpperCase() + key.slice(1);
                            resText += '</span>: ' + res[key];
                            resText += '</li>';
                        }
                        resText += '</ul>';
                        res = resText;
                    }catch(e){
                    }
                }
                $form.parent().parent().find('#eventField').removeClass('info').addClass('error').addClass('active').html(res);
            }).always(function () {
                $form.find('button').text(originalText).removeAttr('disabled');
            });
        });
    }
})();
