export function redirect(url) {
    if (!url) {
        url = '/';
    }
    window.location = url;
}

export function conditionalRedirect(url) {
    var githubcode = localStorage.getItem('githubcode');

    if (githubcode) {
        $.get('/github_auth?connect=true&code=' + githubcode, function () {
            // Cleanup
            localStorage.removeItem('githubcode');
            localStorage.removeItem('githubmail');
            redirect(url);
        });
    } else {
        redirect(url);
    }
}
