import $ from 'jquery';

$(function () {
    $('#team .member').click(function() {
        $(this).toggleClass('flipped');
    });
    $('#team .member a').click(function(e) {
        e.stopPropagation();
    });
});
