import $ from 'jquery';
import './vendor/bootstrap/js/affix'
import acquisitionsTracker from '../../../../../app/static/app/js/packages/acquisitions_tracker/acquisitions_tracker';
import './pages/company';
import './pages/forgot';
import './pages/home';
import './pages/login';
import './pages/join/plan';
import './pages/privacy';
import './pages/resetpassword';
import './pages/tos';
import './pages/verify_email';


function trackEvent (category, action, label){
    var dev = (window.location.host == 'www.sourcelair.com') ? false : true;
    if (dev)
        return;
    ga('send', 'event', category , action, label);
}
window.trackEvent = trackEvent;

$('#github-signup-link').click(function() {
    trackEvent('homepage', 'signup_with_github');
});

$('#emmetio-link').click(function() {
    trackEvent('homepage', 'emmet');
});

$('header a').click(function() {
    var text = $(this).text().split(' ').join('-');
    if (text == '--')
        text = 'home';
    trackEvent('header-link', 'click', text);
});

$('footer a').click(function () {
    var text = $(this).text().split(' ').join('-');
    trackEvent('footer-link', 'click', text);
});

$('#laravel-blueprint-card a').click(function() {
    trackEvent('homepage', 'blueprint_laravel');
});

$('#express-blueprint-card a').click(function() {
    trackEvent('homepage', 'blueprint_expressjs');
});

$('#django-rest-blueprint-card a').click(function() {
    trackEvent('homepage', 'blueprint_django_rest');
});

$('#react-blueprint-card a').click(function() {
    trackEvent('homepage', 'blueprint_reactjs');
});

/*
    * Set autocapitalize and autocorrect to "off" for all inputs.
    *
    * We are doing this with JavaScript instead of HTML, in order to pass the W3C validation of
    * our HTML code.
    */
$('input').attr('autocapitalize', 'off').attr('autocorrect', 'off');

/* Auto scroll to selector when the data-scroll-to selector is available in a hyperlink */
$('a[data-scroll-to]').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    var $scrollToTarget = $($(this).data('scroll-to')),
        $scrollTop = $scrollToTarget.offset().top - 8;
    $('html,body').animate({
        /* Scroll 8px higher in order to give it some space */
        'scrollTop': $scrollTop
    }, 300, function () {
        if ($scrollToTarget.attr('id')) {
            window.location.hash = '#' + $scrollToTarget.attr('id');
            /* Reposition scroll, which got its 8px margin resetted by the above line */
            $('html,body').scrollTop($scrollTop);
        }
    });
});

if (document.querySelector('#affixed-container')) {
    $('#affixed-container').affix({
        offset: {
            top: $('#affixed-container').offset().top -    // distance from document top
            $('header').height() -    // because we want to start when header touches it
            16,    // the padding given to the .affix class, to not touch the top
            bottom: $('footer').height() +
            96    // the padding-bottom of the section element
        }
    });
}
