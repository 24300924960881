import '../polyfills/startswith';
import json from '../utils/json';


var exports = {};

function parseTrackingParameters() {
    var result = {},
        tmp,
        completeHost = window.location.protocol + '//' + window.location.hostname;
    location.search.replace('?', '').split('&').forEach(function (item) {
        tmp = item.split('=');
        if (tmp[0].startsWith('utm')) {
            result[tmp[0]] = tmp[1];
        }
    });
    if (document.referrer && !document.referrer.startsWith(completeHost)){
        result.referrer = document.referrer;
    }
    result.created = (new Date()).toISOString();
    return result;
}

exports.getTrackingParameters = function getTrackingParameters() {
    var value = window.localStorage.getItem('acquisitions-path');
    try {
        value = json.parse(value);
        if (Array.isArray(value)) {
            return value;
        }
    } catch (exception) {}
    return [];
};

exports.clearTrackingParameters = function clearTrackingParameters() {
    window.localStorage.removeItem('acquisitions-path');
};

exports.popTrackingParameters = function popTrackingParameters() {
    var value = exports.getTrackingParameters();
    exports.clearTrackingParameters();
    return value;
};

function trackParameters() {
    var tracking = parseTrackingParameters();
    if (Object.keys(tracking).length > 1) {
        var previousTrackings = exports.getTrackingParameters(),
            lastTracking = (previousTrackings.length > 0) ? previousTrackings[0] : {},
            noCreatedTracking = json.parse(JSON.stringify(tracking)),
            noCreatedLastTracking = json.parse(JSON.stringify(lastTracking));
        delete noCreatedTracking.created;
        delete noCreatedLastTracking.created;
        if (JSON.stringify(noCreatedTracking) !== JSON.stringify(noCreatedLastTracking)) {
            window.localStorage.setItem('acquisitions-path',
                                        JSON.stringify([tracking].concat(previousTrackings)));
        }
    }
}

trackParameters();

export default exports;
